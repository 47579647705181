import React from 'react';
import type { DialogProps } from '@archipro-design/aria';
import { Flex } from '@archipro-design/aria';
import { useStyles } from '@archipro-design/aria';
import { pxArrayToRem, useTheme } from '@archipro-design/aria';
import { Button } from '@archipro-design/aria';
import { Dialog } from '@archipro-design/aria';
import { pxToRem } from '@archipro-design/aria';
import * as S from './DeleteItemConfirmModal.style';

type Props = Pick<DialogProps, 'open' | 'onCancel' | 'onConfirm'> & {
    title: string;
    content?: string;
    loading?: boolean;
};

const DeleteItemConfirmModal: React.FC<Props> = ({
    title,
    content,
    open,
    onCancel,
    onConfirm,
    loading,
}: Props) => {
    const { css } = useStyles();
    const theme = useTheme();
    return (
        <Flex hAlign={'center'} vAlign={'center'}>
            <Dialog
                open={open}
                onCancel={onCancel}
                onConfirm={onConfirm}
                headerAction={null}
                content={{ content }}
                header={{
                    children: title,
                    variables: (siteVars) => ({
                        color: siteVars?.colors.charcoal['250'],
                    }),
                }}
                variables={{
                    rootBorderRadius: 0,
                    rootWidth: pxToRem(450),
                    headerFontSize: theme.siteVariables.fontSizes.label01,
                    headerMargin: pxArrayToRem([43, 24, 4, 24]),
                    overlayBackground: 'rgba(0, 0, 0, 0.2)',
                    overlayBackdrop: 'blur(2px)',
                }}
                confirmButton={{
                    children: () => (
                        <Button
                            loading={loading}
                            color={'primary'}
                            onClick={onConfirm}
                            size={18}
                        >
                            YES
                        </Button>
                    ),
                }}
                cancelButton={{
                    children: () => (
                        <Button
                            disabled={loading}
                            color={'white'}
                            onClick={onCancel}
                            size={18}
                        >
                            NO
                        </Button>
                    ),
                }}
                className={css(S.DeleteItemConfirmModal)}
            />
        </Flex>
    );
};

export default DeleteItemConfirmModal;
