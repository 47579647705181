import type { ActionFunction } from '@remix-run/node';
import type { DirectoryItemDeleteMutationVariables } from 'generated/graphql';
import invariant from 'tiny-invariant';
import { deleteDirectoryItem } from '~/modules/directory/api/delete-directory-item.server';

export const action: ActionFunction = async ({ request, context }) => {
    const data = await request.formData();
    const id = data.get('ID');
    if (!id) {
        invariant(id, 'ID param is required.');
    }
    const variables: DirectoryItemDeleteMutationVariables = {
        DirectoryItemID: Number(id),
    };

    return await deleteDirectoryItem({
        ...context,
        ...variables,
    });
};

export const DELETE_DIRECTORY_ITEM =
    '/remix-api/directory/delete-directory-item';
