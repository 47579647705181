import type { StyleRule } from '@archipro-design/aria';
import { pxToRem, pxArrayToRem } from '@archipro-design/aria';
import {
    dialogClassName,
    dialogSlotClassNames,
} from '@fluentui/react-northstar';
import { HeaderClassName, buttonClassName } from '@archipro-design/aria';

export const DeleteItemConfirmModal: StyleRule = ({ theme }) => ({
    [`&.${dialogClassName}`]: {
        overflow: 'hidden',
        backgroundColor: theme.siteVariables.colors.white['100A'],

        [`& .${HeaderClassName}`]: {
            ...theme.siteVariables.textStyles.Text.Medium65,
            width: `calc(100% - ${pxToRem(48)})`,
            fontSize: theme.siteVariables.fontSizes.label01,
            paddingBottom: pxToRem(17),
            borderBottom: `2px solid ${theme.siteVariables.colors.charcoal['250']}`,
        },

        [`& .${dialogSlotClassNames.content}`]: {
            margin: pxArrayToRem([20, 24, 0, 24]),
            padding: 0,
            borderTop: 'none',
            color: theme.siteVariables.colors.charcoal['250'],
            fontSize: pxToRem(16),
            lineHeight: pxToRem(24),
            width: 'auto',
        },

        [`& .${dialogSlotClassNames.footer}`]: {
            padding: pxArrayToRem([0, 14]),
            [`& .${buttonClassName}`]: {
                padding: pxArrayToRem([12, 44]),
                margin: pxArrayToRem([24, 10]),
                textTransform: 'uppercase',
                '&:first-of-type': {
                    backgroundColor: theme.siteVariables.colors.white['100A'],
                },
            },
        },
    },
});
