import { useFetcher } from '@remix-run/react';
import { useState, useEffect } from 'react';
import type { action } from '~/routes/remix-api.directory.delete-directory-item';
import { DELETE_DIRECTORY_ITEM } from '~/routes/remix-api.directory.delete-directory-item';
import { fetcherIsDone } from '~/utils/fetcherHelper';
import { useToaster } from '~/modules/root/context/ToasterContext';

export const useDeleteItemWithConfirm = () => {
    const [itemID, setItemID] = useState<number | undefined>(undefined);
    const deleteFetcher = useFetcher<typeof action>();
    const { toast } = useToaster();

    // Confirm to delete item
    const confirmDelete = async () => {
        if (!itemID || itemID <= 0) {
            return;
        }

        deleteFetcher.submit(
            {
                ID: itemID.toString(),
            },
            {
                method: 'post',
                action: DELETE_DIRECTORY_ITEM,
            }
        );
        setItemID(undefined);
    };

    useEffect(() => {
        if (
            fetcherIsDone(deleteFetcher) &&
            deleteFetcher.data.deleteDirectoryItem === true
        ) {
            toast(
                'Project deleted, please allow a few minutes for results to be updated.',
                { type: 'success' }
            );
        }
    }, [deleteFetcher, toast]);

    const willDelete = (itemIDToDelete: number) => {
        setItemID(itemIDToDelete);
    };

    const cancelDelete = () => {
        setItemID(undefined);
    };

    return {
        itemID,
        willDelete,
        confirmDelete,
        cancelDelete,
    };
};
